import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class BituminousMixturesRepository extends BaseRepository {
    baseUrl = 'management/bituminous-mixtures';

    /**
     * Returns all composition fields for the bituminous mixtures.
     *
     * @returns {*}
     */
    compositionMixtureFields() {
        return this.httpClient.get(`${this.baseUrl}/composition-fields/mixture`);
    }
}
